import React, { useState , useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/Dashboard.css'
import '../css/projects.css';
import '../css/print.css';
import Footer from '../Component/Footer';
import Accordions from '../Component/Accordion';
// import DashboardTheory from '../Component/DashboardTheory';
import { getDashboardData, getConsolidatedDashboard, getProjectScores } from '../Services/DashboardService';
// import ImpactDashboard from "./ImpactDashboard";
import DBGImpactDashboard from "./Clients/DBGImpactDashboard";
import DashboardTheory from '../Component/Clients/DashboardTheory/CBGDashboardTheory';
const ConsolidatedDashboard = () => {
    const [allDashboardData, setAllDashboardData] = useState({});
    const [dashboardArray, setDashboardArray] = useState([]);
    const [clientList, setclientList] = useState([
        {
            "name": "Consolidated Bank Ghana",
            "slug": "cbg"
        },
        {
            "name": "Fidelity Bank",
            "slug": "fidelity_bank"
        }
    ]);
    

    const getDistributionScores = async(project_ids) => {
        var request_data = {"project_ids": project_ids};
        return await getProjectScores(project_ids).then((response) => {
            return response.data
        }).catch((error) => {})
    }

    const fetchConsolidatedDashboard = async(client_name) => {
        return await getConsolidatedDashboard(client_name).then(async(response) => {
            let project_ids = response.data.project_ids;
            let temp = response.data;
            if(client_name == ''){
                await getDistributionScores(project_ids).then((distributionScores) => {
                    temp["distribution_scores"] = distributionScores;
                }).catch((error) => {})
            }
            return temp;
        }).catch((error) => {})
    }

    useEffect(() => {
        fetchConsolidatedDashboard('').then((response) => {
            setAllDashboardData(response)
        });
        clientList.map(async(row) => {
            let client_slug = row.slug;
            await fetchConsolidatedDashboard(client_slug).then(async(response) => {
                let temp = response;
                temp["client_name"] = row.name;

                let newArray = dashboardArray.filter((f) => f.client_name == row.name)
                if(newArray.length == 0){
                    setDashboardArray(prev => [...prev, temp]);
                }
            }).catch((error) => {})
        })
    }, [clientList]);

return (
    <>
      <div className="container-fluid card-impact-report">
        <div className="row logo-dropdown horizontal-line">
          <div className="col-md-6 ">
            <img src="/impactGini.png" alt="" className="img-fluid impact-gini-img"  loading="lazy" / >
          </div>
        </div><hr className='horizontal-line'/>
        <DBGImpactDashboard
          dashboardData={{...allDashboardData}}
          title={'Impact Analysis - All'}
          mode={'consolidated'}
          client_names={["all"]}
          showDashboardTheory={false}
        />
        {
            dashboardArray.map((item, i) => {
                let dashboard_name = "Impact Analysis - " + item["client_name"];
                return (
                    <DBGImpactDashboard
                        keys={i}
                        dashboardData={{...item}}
                        title={dashboard_name}
                        mode={'single'}
                        client_names={item["client_names"]}
                        showDashboardTheory={false}
                    />
                )      
            })
        }
        <Accordions className={'mb-1'} header={"Impact Definitions"} body={
            <div className='accordion-border' style={{border: "16px solid #dfeee2", padding: "1rem"}}>
                <DashboardTheory tabType="MSME" />
            </div>
        } />
      </div>
      <Footer/>
    </>
  )
}
export default ConsolidatedDashboard