// export const baseUrl = "https://dev-13.gac-lap.com";
export const baseUrl = "https://uat.gac-lap.com";
// export const baseUrl = "http://127.0.0.1:5000";

export const LOGIN_URL = "/signin";
export const REGISTER_URL = "/register";
export const USER_PROFILE_URL = "/get_user_profile";
export const CLIENT_URL = "/api/clients/allowed";
export const IMPACT_DASHBOARD_URL = "/user/projects/analytics";
export const CONSOLIDATED_DASHBOARD_URL = "/api/project/consolidated-dashboard-report";
export const GET_PROJECT_SCORES_URL = "/api/project/get_project_scores";
export const DOWNLOAD_MCT_OUTPUT = '/api/upload_file/download_mct_output';
export const DOWNLOAD_IMPACT_SCORE = '/api/upload_file/download_impact_score';
export const DOWNLOAD_DISPERSED_IMPACT_SCORE = '/api/upload_file/download_impact_score_dispersed';