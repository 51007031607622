import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../css/FileUpload.css';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import { siteCall, fileUploadSiteCall } from '../utils/WebCall';
import { baseUrl } from "../utils/urls";
import { getToken, getLoggedInUser } from "../Services/AuthService";
import { downloadMCT, downloadImpactScore, downloadDImpactScore } from "../Services/FileUploadService";
import { getClients } from "../Services/ClientService";
import { useAuth } from "../Providers/AuthProvider";
import moment from 'moment';

const FileUpload = () => {
    const { userData } = useAuth();
    const fileInputRef = useRef(null);
    const currentPageName = "Upload File";
    const [files, setFiles] = useState([]);
    const [projects, setProjects] = useState([]);
    const [clientName, setClientName] = useState("");
    const [timeFrame, setTimeFrame] = useState("");
    const [projectName, setProjectName] = useState("");
    const [jwt, setJWT] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [visibleMenus, setVisibleMenus] = useState({});
    const menuRefs = useRef([]);
    const [fileName, setFileName] = useState("Choose file");
    const [lenderList, setLenderList] = useState([]);

    const fetchLenderList = async () => {
        getClients().then((response) => {
            setLenderList(response.data);
        }).catch((error) => {

        })
    };

    const getProjects = async () => {
        const config = {
            url: `${baseUrl}/api/project/get-all`,
            method: "GET",
            successCallback: (response) => {
                let data = response.data;
                setProjects(data.data);
            },
            errorCallback: (error) => {
                console.error("Error fetching projects:", error);
            }
        };
        await siteCall(config);
    };

    useEffect(() => {
        getProjects();
        setJWT(getToken());
        fetchLenderList();
    }, [userData]);

    const download_manage_uploaded_file = (url) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.click();
    }

    const get_download_url_for_uploaded_file = (uploaded_file_id) => {
        const url = `${baseUrl}/api/file/download?` + new URLSearchParams({ uploaded_file_id });
        const success_callback = (response) => {
            download_manage_uploaded_file(response.data['url']);
        };
        siteCall({ url, method: 'GET', successCallback: success_callback });
    }

    const downloadFile = (id) => {
        get_download_url_for_uploaded_file(id);
    }

    const deleteFile = async (id) => {
        if (!window.confirm("Do you want to delete this project?")) {
            return;
        }

        const config = {
            url: `${baseUrl}/api/project/delete?project_id=${id}`,
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            successCallback: (response) => {
                alert("Project deleted");
                getProjects();
            },
            errorCallback: (error) => {
                alert("Something went wrong");
                console.error("Error deleting project:", error);
                getProjects();
            }
        };

        await siteCall(config);
    }

    const resetForm = () => {
        setFiles([]);
        setClientName("");
        setTimeFrame("");
        setProjectName("");
        setFileName("Choose file");
    }

    const uploadFiles = async () => {
        const formData = new FormData();
        const errors = [];
        setIsSubmitted(true);
        if (clientName === "") {
            errors.push("Please select client");
        }
        if (timeFrame === "") {
            errors.push("Please select time frame");
        }
        if (projectName === "") {
            errors.push("Please enter project name");
        }
        if (files.length === 0) {
            errors.push("Please select files");
        }

        let errorStr = "";
        if (errors.length > 0) {
            errors.map((msg) => {
                errorStr += msg + "\n";
            });
            alert(errorStr);
            setIsSubmitted(false);
            return;
        }

        formData.append("file", files[0]);
        formData.append("project_name", projectName);
        formData.append("client_name", clientName);
        formData.append("time_frame", timeFrame);

        const url = `${baseUrl}/api/file/load_file`;
        await fileUploadSiteCall(null, url, "POST", formData, {},
            (response) => {
                alert("File uploaded")
                getProjects();
                setIsSubmitted(false);
                resetForm();
            },
            (error) => {
                setIsSubmitted(false);
                let errorArray = error?.response?.data || {};
                let errors = errorArray?.errors;
                let errorStr = "";
                if (typeof errors == "object") {
                    Object.entries(errors).map(([key, value]) => {
                        console.log(key, value)
                        errorStr += value + "\n";
                    })
                    if (Object.keys(errors).length > 0) {
                        alert(errorStr);
                    }
                } else {
                    alert(errors);
                }
            }
        );
    };

    const selectFiles = (e) => {
        const files = Array.from(e.target.files);
        setFiles(files);
        setFileName(files.length > 0 ? files[0].name : "Choose file");

    };

    const toggleMenu = (index) => {
        setVisibleMenus((prevVisibleMenus) => ({
            ...prevVisibleMenus,
            [index]: !prevVisibleMenus[index]
        }));
    };

    const handleClickOutside = useCallback((event) => {
        menuRefs.current.forEach((menu, index) => {
            if (menu && !menu.contains(event.target)) {
                setVisibleMenus((prevVisibleMenus) => ({
                    ...prevVisibleMenus,
                    [index]: false
                }));
            }
        });
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <>
            <Header currentPageName={currentPageName} />
            <div className="fileupload-view">
                <div className='container-fluid'>
                    <div className='col-md-12'>
                        <div className='col-md-12'>
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <h4 className='text-center upload-file'>Upload Project Dataform​</h4>
                                    <div className='row py-4 form-section'>
                                        <div className="col-md-3">
                                            <select name="lender" required="" className="form-control" id="client" value={clientName} onChange={(e) => setClientName(e.target.value)}>
                                                <option value="all">Select Lender</option>
                                                {
                                                    lenderList.map((item, i) => {
                                                        return (
                                                            <option value={item.slug} key={i}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <select name="timeframe" required="" className="form-control" id="timeframe" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
                                                <option value="all">Select Time Frame</option>
                                                <option value="q4_2023">Q4 2023</option>
                                                <option value="q1_2024">Q1 2024</option>
                                                <option value="q2_2024">Q2 2024</option>
                                                <option value="q3_2024">Q3 2024</option>
                                                <option value="q4_2024">Q4 2024</option>
                                            </select>
                                        </div>
                                        <div className='col-md-2'>
                                            <input type='text' className='form-control' placeholder='Project Name' id='project_name' value={projectName} onChange={(e) => setProjectName(e.target.value)} required />
                                        </div>
                                        <div className="col-md-2">
                                            <input type="file" className="custom-file-input" id="file-upload" onChange={selectFiles} />
                                            <label className="custom-file-label" htmlFor="file-upload">{fileName}</label>
                                        </div>
                                        <div className="col-md-2">
                                            <button className="btn-upload-label btn" onClick={uploadFiles} disabled={isSubmitted}>Upload File</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className="col-md-12" style={{ textAlign: "center", fontSize: "14px" }}>
                            Please upload a csv / xlsx file.
                        </div>
                        <div className='col-md-12'>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead className="text-center">
                                        <tr style={{ borderBottom: "1px solid lightgrey" }}>
                                            <th>Project Name</th>
                                            <th>Lender</th>
                                            <th>Time Frame</th>
                                            <th>Created Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {((userData?.organisation_description == "dbg" || userData?.user_roles?.indexOf('superuser') != -1 ) && projects.length > 0) && (
                                            <tr>
                                                <td>
                                                    <a href={`/consolidated-dashboard?jwt=${jwt}`} target="_blank">
                                                        Consolidated Dashboard - DBG
                                                    </a>
                                                </td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>
                                                    <span>-</span>
                                                    <span>-</span>
                                                </td>
                                            </tr>
                                        )}
                                        {projects.length > 0 ? (
                                            projects.map((item, index) => {
                                                let uploaded_files = item.uploaded_files || [];
                                                let uploaded_file_object = (uploaded_files.length > 0) ? uploaded_files[0]: {};
                                                let created = moment(item.created).format('DD-MM-YYYY h:mm a');
                                                let impact_sectors = item.impact_sectors.join(',')
                                                return (
                                                    <tr key={index} ref={(el) => menuRefs.current[index] = el}>
                                                        <td>
                                                            <a href={`/dashboard/${item._id}?impact_sectors=${impact_sectors}&jwt=${jwt}`} target="_blank">
                                                                {item.project_name}
                                                            </a>
                                                        </td>
                                                        <td>{item.client || "-"}</td>
                                                        <td>{item.time_frame ? item.time_frame.split("_").join(" ").toUpperCase() : "-"}</td>
                                                        <td>{created}</td>
                                                        <td>
                                                            <span
                                                                onClick={() => toggleMenu(index)}
                                                                style={{ cursor: 'pointer', fontSize: '20px', paddingRight: '10%' }}
                                                            >
                                                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                            </span>
                                                            {visibleMenus[index] && (
                                                                <div className="fileuploadmenu">
                                                                    <div onClick={() => downloadFile(uploaded_file_object._id)}>Download File</div>
                                                                    <div onClick={() => downloadMCT({project_id: item._id})}>Download MCT</div>
                                                                    <div onClick={() => downloadImpactScore({project_id: item._id})}>Download Score</div>
                                                                    <div onClick={() => downloadDImpactScore({project_id: item._id})}>Download D-Score</div>
                                                                    <div onClick={() => deleteFile(item._id)}>Delete</div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="5">No Projects </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <span id="noFiles1" className="d-block text-center"></span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default FileUpload;
