import React, { useRef, useEffect } from 'react';
import Chart from 'react-apexcharts';
import '../css/Dashboard.css';

const BoxPlotChart = ({ data }) => {
  const chartRef = useRef(null);
  // if (!Array.isArray(scatterData)) {
  //   console.error('scatterData should be an array:', scatterData);
  //   scatterData = [];
  // }

   // Static scatter data
   const scatterData = [
    { x: 'Category 1', y: [1, 6, 8,9] },
    { x: 'Category 2', y: [3, 7, 10,2] },
    { x: 'Category 3', y: [5, 4, 2,5] },
  ];

  const boxPlotData = [
    {
      name: 'Box Plot',
      type: 'boxPlot',
      data: data.map(group => ({
        x: group.x,
        y: group.y,
      })),
    },
    // ...scatterData.map((scatter, index) => ({
    //   name: `Outliers ${index + 1}`,
    //   type: 'scatter',
    //   data: scatter.y.map(value => ({ x: scatter.x, y: value })),
    // })),
  ];

  useEffect(() => {
    const handleBeforePrint = () => {
      const chartElement = chartRef.current.chart;
      chartElement.updateOptions({
        chart: {
          height: 250,
          width: '100%',
        },
      }, false, true);
    };

    const handleAfterPrint = () => {
      const chartElement = chartRef.current.chart;
      chartElement.updateOptions({
        chart: {
          height: 250,
          width: '100%',
        },
      }, false, true);
    };

    window.onbeforeprint = handleBeforePrint;
    window.onafterprint = handleAfterPrint;

    return () => {
      window.onbeforeprint = null;
      window.onafterprint = null;
    };
  }, []);

  const options = {
    chart: {
      type: 'boxPlot',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: '#c0ddef',
          lower: '#c0ddef',
        },
      },
      scatter: {
        colors: '#c0ddef',
      }
    },
    xaxis: {
      categories: data.map(group => group.x),
      labels: {
        style: {
          fontSize: '12px',
          whiteSpace: 'normal',
        },
      },
    },
    yaxis: {
      min: 0,
      // max: Math.max(...scatterData.map(outlier => outlier.y)) + 5, // Adjusted to accommodate the range of outliers
      max:10,
      labels: {
        formatter: function (value) {
          return value.toFixed(1);
        },
      },
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        if (seriesIndex === 0) { // BoxPlot series
          const dataPoint = data[dataPointIndex];
          return `
            <div class="fs-12 px-3 py-2" style='font-size:12px;'>
              <p class='mb-1' style="font-size: 12px;">
                <b>Maximum:</b> <span>${dataPoint.y[4].toFixed(1)}</span>
              </p>
              <p class='mb-1' style="font-size: 12px;">
                <b>75th percentile:</b> <span>${dataPoint.y[3].toFixed(1)}</span>
              </p>
              <p class='mb-1' style="font-size: 12px;">
                <b>Median:</b> <span>${dataPoint.y[2].toFixed(1)}</span>
              </p>
              <p class='mb-1' style="font-size: 12px;">
                <b>25th percentile:</b> <span>${dataPoint.y[1].toFixed(1)}</span>
              </p>
              <p class='mb-1' style="font-size: 12px;">
                <b>Minimum:</b> <span>${dataPoint.y[0].toFixed(1)}</span>
              </p>
            </div>
          `;
        } else { // Scatter series
          const outlier = scatterData[seriesIndex - 1].y[dataPointIndex];
          return `
            <div class="fs-12 px-3 py-2" style='font-size:12px;'>
              <p class='mb-1' style="font-size: 12px;">
                <b>Outlier:</b> <span>${outlier.toFixed(1)}</span>
              </p>
            </div>
          `;
        }
      }
    },
    markers: {
      size: 5, // Set the size of scatter markers
      colors: '#c0ddef',
      strokeColors: '#000',
      strokeWidth: 1,
      shape: "circle",
      hover: {
        sizeOffset: 3
      },
    },
    legend: {
      show: false, // Show legends for multiple scatter series
    },
  };

  return (
    <div className="box-plot-chart">
      <Chart options={options} series={boxPlotData} type="boxPlot" height="280" className="box-plot-chart-print-width" ref={chartRef} />
    </div>
  );
};

export default BoxPlotChart;
